#chat-window {
    background-color: rgba(191, 191, 191, 0.15);
    padding: 10px;
}

.dx-htmleditor {
    height: 100px;
}

#send {
    float: right;
    margin: 10px 0;
}

.message {
    height: auto;
    background-color: inherit;
    display: inline-block;
    max-width: 100%;
    border-radius: 5px;
    margin: 5px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.name {
    font-weight: bold;
}

.date {
    opacity: 0.5;
}

.photo {
    float: left;
    height: 100%;
}

    .photo > img {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 40px;
        margin-right: 10px;
    }
