.dx-tabpanel .dx-tabs {
  display: table;
}

.dx-tabpanel .dx-tabs-wrapper {
  display: table-row;
}

.dx-popup-title {
  background-color: whitesmoke
}

.dx-popup-content {
  padding-top: 18px;
  padding-bottom: 22px;
  padding-left: 18px;
  padding-right: 18px;
}


//UPLOADER
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}

.segment-size,
.loaded-size {
  margin-left: 3px;
}

.note {
  display: block;
  font-size: 10pt;
  color: #484848;
  margin-left: 9px;
}

.note>span {
  font-weight: 700;
}
//UPLOADER


#gridContainer .green .diff {
  // background-color: rgb(238, 115, 115);
  color: rgb(19, 158, 42);
  font-weight: bold;
}

#gridContainer .yellow .diff {
  color: #bcbe10;
  font-weight: bold;
}

#gridContainer .red .diff {
  color: #f00;
  font-weight: bold;
}

.dx-fileuploader-input-wrapper {
  // padding: 7px 0;
  // border: 3px dashed rgba(102,102,102,.87);
  text-align: center;
  padding: 25px 3px;
  border: 3px dashed #e0e0e0;
}

.dx-fileuploader-button {
  margin-left: 233px;
}

.dx-fileuploader-dragover .dx-fileuploader-input-wrapper {
  padding: 0px;
  border: none;
}

.dx-fileuploader-dragover .dx-fileuploader-input {
  display: block;
  width: 100%;
  padding: 37px 3px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.dx-fileuploader-dragover .dx-fileuploader-input-label {
  text-align: center;
  padding: 35px 3px 10px;
  border: 3px dashed #e0e0e0;
}



.logos-container {
  margin: 20px 0 40px 0;
  text-align: left;

  svg {
    display: inline-block;
  }
}

.screen-large .responsive-paddings {
  padding: 40px;
  margin-right: 400px;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}


//VERTICAL-MEMO-LIST
.options {
  padding: 10px 15px 10px 10px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 100px;
  width: 400px;
}

.caption {
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  padding-left: 15px;
}

.option {
  margin-top: 20px;
}



#gridContainer .green .diff {
  // background-color: rgb(238, 115, 115);
  color: rgb(19, 158, 42);
  font-weight: bold;
}

#gridContainer .yellow .diff {
  color: #bcbe10;
  font-weight: bold;
}

#gridContainer .red .diff {
  color: #f00;
  font-weight: bold;
}

#gridContainer .lightblue .diff {
  color: rgb(188, 221, 231);
  font-weight: bold;
}

#gridContainer .bluette .diff {
  color: rgb(147, 147, 248);
  font-weight: bold;
}

.dx-fileuploader-input-wrapper {
  // padding: 7px 0;
  // border: 3px dashed rgba(102,102,102,.87);
  text-align: center;
  padding: 25px 3px;
  border: 3px dashed #e0e0e0;
}

.dx-fileuploader-button {
  margin-left: 233px;
}

.dx-fileuploader-dragover .dx-fileuploader-input-wrapper {
  padding: 0px;
  border: none;
}

.dx-fileuploader-dragover .dx-fileuploader-input {
  display: block;
  width: 100%;
  padding: 37px 3px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.dx-fileuploader-dragover .dx-fileuploader-input-label {
  text-align: center;
  padding: 35px 3px 10px;
  border: 3px dashed #e0e0e0;
}


.dx-checkbox-container {
  padding: 0px !important;
}




.multiple-selection-styling-mode {
  border-left-width: 0px;
  border-left-style: solid;
  border-color: #ddd;
  padding-right: 140px;
}

.multiple-selection-styling-mode .dx-button {
  height: 26px !important;
}

.dx-button.dx-button-warning {
  background-color: #ffc107;
}

.dx-button.dx-button-compilato {
  background-color: rgb(188, 221, 231);
}

.dx-button.dx-button-inlavorazione {
  background-color: rgb(147, 147, 248);
}


.dx-button-text {
  line-height: 18px !important;
}

.hide-element {
  display: none !important;
}

.show-element {
  display: block !important;
}

// fields padding

.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 12px !important;
}

.dx-layout-manager .dx-field-item:not(.dx-last-col) {
  padding-right: 0px !important;
}

.dx-layout-manager .dx-field-item {
  padding-bottom: 5px;
  font-size: 12px;
}

.dx-tabs-inquilino {
  visibility: hidden;
}

.dx-non-show {
  visibility: hidden !important;
}

.dx-show {
  visibility: visible !important;
}



.grid-height-search-impianto {
  position: absolute;
  display: flex;
  width: 100%;
  max-height: calc(100% - 140px) !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  padding-left: 32px !important;
}



div.toolbarSearch .dx-texteditor.dx-editor-filled .dx-placeholder::before,
div.toolbarSearch .dx-texteditor.dx-editor-filled .dx-texteditor-input,
div.toolbarSearch .dx-texteditor.dx-editor-outlined .dx-placeholder::before,
div.toolbarSearch .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding-left: 40px !important;
}



.tooltipContent div {
  text-align: left;
}