@import "../../themes/generated/variables.base.scss";

.login-form {
    .link {
        text-align: center;
        font-size: 16px;
        font-style: normal;

        a {
            text-decoration: none;
        }
    }

    .form-text {
        margin: 10px 0;
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
}

.form-control {
    width: 75%;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: #ff6a00 !important;
    color: #ffffff !important;
    border-color: orange;
    width: 75%;
}

.btn-primary:disabled {
    background-color: darkgray !important;
    color: #ffffff !important;
    border-color: darkgray;
    width: 75%;
}


