#gridContainer td {
    vertical-align: middle;
  }
  
  #gridContainer .chart-cell {
    overflow: visible;
  }
  
  #gridContainer .current-value {
    display: inline-block;
  }
  
  #gridContainer .diff {
    font-weight: bold;
  }
  
  #gridContainer .yellow .diff {
    color: #bcbe10;
  }
  
  // #gridContainer .inc .diff::before {
  //   content: '+';
  // }
  
  // #gridContainer .dec .diff::before {
  //   content: '-';
  // }
  
  #gridContainer .red .diff {
    color: #f00;
  }

  // #gridContainer .inc .diff::after,
  // #gridContainer .dec .diff::after {
  //   content: '';
  //   display: block;
  //   height: 10px;
  //   width: 10px;
  //   position: absolute;
  //   right: -12px;
  //   top: 6px;
  //   background-repeat: no-repeat;
  //   background-size: 10px 10px;
  // }
  

  .dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 13px!important;
    transition: height .2s linear .15s,background-color .2s linear .15s;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 13px;
}