body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 1px;
    text-align: left;
    background: red;
    color: white;
    font-size: 30px;
}




#rcorners1 {
    border-radius: 25px;
    background: orangered;
    padding: 20px;
    height: 73px;
}

#rcorners2 {
    border-radius: 25px;
    background: #73AD21;
    /*Green*/
    padding: 20px;
    height: 73px;
    font-size: 25px;
}



#bottomButtons {
    font-size: 30px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 37px) ;

}

#buttons {

    display: flex !important;
    justify-content: center !important;
}

.textArea {
    height: 250px;
}