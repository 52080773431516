.dx-tabpanel .dx-tabs {
  display: table;
}


.dx-tabpanel .dx-tabs-wrapper {
  display: table-row;
}


.logos-container {
  margin: 20px 0 40px 0;
  text-align: left;
  svg {
    display: inline-block;
  }
}

.screen-large .responsive-paddings {
    padding: 40px;
    margin-right: 400px;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}


//UPLOADER
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}

.segment-size,
.loaded-size {
  margin-left: 3px;
}

.note {
  display: block;
  font-size: 10pt;
  color: #484848;
  margin-left: 9px;
}

.note>span {
  font-weight: 700;
}

//UPLOADER


//VERTICAL-MEMO-LIST
.options {
    padding: 10px 15px 10px 10px;
    background-color: rgba(191, 191, 191, 0.15);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 100px;
    width: 400px;
}

.caption {
    font-weight: 500;
    font-size: 18px;
    padding-top: 20px;
    padding-left: 15px;
}

.option {
    margin-top: 20px;
}



#gridContainer .green .diff {
  // background-color: rgb(238, 115, 115);
  color: rgb(19, 158, 42);
  font-weight: bold;
}

#gridContainer .yellow .diff {
  color: #bcbe10;
  font-weight: bold;
}

#gridContainer .red .diff {
  color: #f00;
  font-weight: bold;
}

.dx-fileuploader-input-wrapper {
  // padding: 7px 0;
  // border: 3px dashed rgba(102,102,102,.87);
  text-align: center;
  padding: 25px 3px;
  border: 3px dashed #e0e0e0;
}
.dx-fileuploader-button {
  margin-left: 233px;
}

.dx-fileuploader-dragover .dx-fileuploader-input-wrapper {
  padding: 0px;
  border: none;
}

.dx-fileuploader-dragover .dx-fileuploader-input {
  display: block;
  width: 100%;
  padding: 37px 3px ;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-fileuploader-dragover .dx-fileuploader-input-label {
  text-align: center;
  padding: 35px 3px 10px;
  border: 3px dashed #e0e0e0;
}


.dx-checkbox-container {
  padding: 10px 20px;
}


.bottom-place {
  position: absolute;
  width: 100%;
  bottom: 90px;
  height: 260px;
}

// .item-pie {
//   height: 250px !important;
//   width: 480px !important;
// }

.grid-height {
  position: absolute;
  display: flex;
  width:100%;
  max-height: calc(100% - 450px) !important;
}

.container-chart {
  display: flex;
  margin-left: 40px;
  margin-right: 10px;
  bottom: 90px;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}