.logos-container {
  margin: 20px 0 40px 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.609);
}

.content-horizontal {
  display: flex;
}

.logo-size {
  height: 130px;
  width: 400px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.item1 {
  padding-right: 100px;
}

.item2 {
  padding-left: 110px;
  flex-grow: 2;
}

.item-padding {
  padding-top: 35px;
  padding-right: 35px;
}

.home-section-top {
  padding-top: 0px;
  padding-left: 25px;
}

.home-section-bottom {
  padding-left: 25px;
  position: absolute;
  bottom: 50px;
}

.validi {
  padding-right: 50px;
}

.scaduti {
  padding-right: 36px;
}

.mancanti {
  padding-right: 24px;
}


.screen-large .responsive-paddings {
  padding: 40px;
  // margin-right: 400px;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}



//VERTICAL-MEMO-LIST
.options {
  padding: 10px 15px 10px 10px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 100px;
  // width: 400px;
}

.caption {
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  padding-left: 15px;
}

.option {
  margin-top: 20px;
}

.full-background {
  position: absolute;
  // width: calc(100% - 467px);
  width: calc(100% - 80px);
  height: calc(100% - 150px);
}