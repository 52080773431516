.options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    margin-top: 20px;
}

.option {
    margin-top: 10px;
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option > span {
    margin-right: 10px;
}

.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
}


.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 13px!important;
    transition: height .2s linear .15s,background-color .2s linear .15s;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 13px;
}


.bottom-place {
    position: absolute;
    width: 100%;
    bottom: 90px;
    height: 260px;
}

.container-chart {
    display: flex;
    margin-left: 40px;
    margin-right: 10px;
    bottom: 90px;
    flex-wrap: nowrap;
}

.item-chart-consumi {
    margin-right: 40px;
    width: 100%;
    height: 260px !important;
}

.item-pie {
    height: 250px !important;
    width: 480px !important;
}

.grid-height {
    position: absolute;
    display: flex;
    width:100%;
    max-height: calc(100% - 450px) !important;
}