.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 13px !important;
    transition: height .2s linear .15s, background-color .2s linear .15s;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 13px;
}

.bottom-place {
    position: absolute;
    width: 100%;
    bottom: 90px;
    height: 260px;
}

.container-chart {
    display: flex;
    margin-left: 40px;
    margin-right: 10px;
    bottom: 90px;
    flex-wrap: nowrap;
}

.item-chart {
    margin-right: 40px;
    width: calc(100% - 550px);
    height: 260px !important;
}

.item-pie {
    height: 250px !important;
    width: 480px !important;
}

.grid-height {
    position: absolute;
    display: flex;
    width:100%;
    max-height: calc(100% - 450px) !important;
}
