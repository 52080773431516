.login,
.image {
    min-height: 100vh;
}

.bg-image {
    background-image: url('../public/login-split-6.jpg');
    background-size: cover;
    background-position: center center;
}
